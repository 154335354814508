import React from "react";
import SolutionsImg from "../assets/Ocean Freight/large-cargo-ship-with-containers-port-generative-ai.jpg"
import Logo1 from "../assets/Brands/logo.png"
import Logo2 from "../assets/Brands/logo2.png"
import Logo3 from "../assets/Brands/logo3.png"
import Logo4 from "../assets/Brands/logo4.png"
import Logo5 from "../assets/Brands/logo5.png"
import Logo6 from "../assets/Brands/logo6.png"
import Logo7 from "../assets/Brands/logo7.png"
import Logo8 from "../assets/Brands/logo8.png"
import Logo9 from "../assets/Brands/logo9.png"


function Markers (){

    return(


<div className="row align-items-center pb-5 pt-5 bg-main-ins ">
<div className="container mx-auto rounded pb-5">
<div className='mx-auto col-12 text-center '>


  <h1 className="mx-auto text-center pb-1 highlighted-text fs-2 fw-medium'">We care, connect, move</h1>
    <div className="row container col-md-6 mx-auto mt-5 pb-5">
      <div className="col-12 col-md-6 d-flex justify-content-center mx-auto">
        <a href="" className="btn btn-lg btn-primary border-warning rounded-0  fw-semibold text-start w-100">
          Request an Offer 
        </a>
      </div>
      <div className="col-12 col-md-6 d-flex justify-content-center mx-auto mt-md-0 mt-3">
        <a href="" className="btn btn-lg btn-primary rounded-0  mt-md-0 fw-semibold text-start w-100">
          Become a subcontractor
        </a>
      </div>
    </div>
    <div class="container mt-5 ">
    <div class="row">
  <div class="custom-card col-md-4 col-12 px-3">
    <img src={SolutionsImg} alt="Example" />
    <div class="custom-overlay">
      <h3>Equipment options</h3>
      <div class="custom-arrow-icon">➡</div>
    </div>
  </div>
  <div class="custom-card col-md-4 col-12 px-3">
    <img src={SolutionsImg} alt="Example" />
    <div class="custom-overlay">
      <h3>Modality options</h3>
      <div class="custom-arrow-icon">➡</div>
    </div>
  </div>
  <div class="custom-card col-md-4 col-12 px-3">
    <img src={SolutionsImg} alt="Example" />
    <div class="custom-overlay">
      <h3>Full load options</h3>
      <div class="custom-arrow-icon">➡</div>
    </div>
  </div>
  </div>
</div>

      </div>
    </div>
  </div>

    )
}

export default Markers;

{/* <h1 className="mx-auto text-center pb-1 highlighted-text fs-2 fw-medium'">Güçlü iş birlikleri</h1>
  <h2 className="fs-2 text-center fw-medium">Çözüm Ortaklarımız</h2>
  <p className=" col-12 col-md-8 col-ld-8 col-xl-6 text-center mx-auto">Sektördeki en güçlü çözüm ortaklarıyla birlikte çalışarak,
   müşterilerimize en yüksek kalitede hizmet sunuyoruz. İş birliklerimizle,
    küresel başarılar elde ediyor ve sürdürülebilir çözümler geliştiriyoruz.</p>
    </div>
  <div className="slider pb-5">
    <div className="logos mt-5">
      <img className="mx-5" src={Logo1} 
      style={{maxWidth:"170px", maxHeight:"170px" }}
      
       />
      
      <img className="mx-5" src={Logo2} 
      style={{maxWidth:"170px", maxHeight:"170px" }}
      
       />
      
      <img className="mx-5" src={Logo3} 
      style={{maxWidth:"170px", maxHeight:"170px" }}
      
       />
      
      <img className="mx-5" src={Logo4} 
      style={{maxWidth:"170px", maxHeight:"170px" }}
      
       />
        <img className="mx-5" src={Logo5} 
      style={{maxWidth:"170px", maxHeight:"170px" }}
      
       />
      
        <img className="mx-5" src={Logo6} 
      style={{maxWidth:"170px", maxHeight:"170px" }}
      
       />
      
        <img className="mx-5" src={Logo7} 
      style={{maxWidth:"170px", maxHeight:"170px" }}
      
       />
        <img className="mx-5" src={Logo8} 
      style={{maxWidth:"170px", maxHeight:"170px" }}
      
       />
      
      <img className="mx-5" src={Logo9} 
      style={{maxWidth:"170px", maxHeight:"170px" }}
      
       />
      
      <img className="mx-5" src={Logo3} 
      style={{maxWidth:"170px", maxHeight:"170px" }}
      
       />
      
      <img className="mx-5" src={Logo4} 
      style={{maxWidth:"170px", maxHeight:"170px" }}
      
       />
        <img className="mx-5" src={Logo1} 
      style={{maxWidth:"170px", maxHeight:"170px" }}
      
       />
      
        <img className="mx-5" src={Logo1} 
      style={{maxWidth:"170px", maxHeight:"170px" }}
      
       />
      
        <img className="mx-5" src={Logo1} 
      style={{maxWidth:"170px", maxHeight:"170px" }}
      
       />
        <img className="mx-5" src={Logo1} 
      style={{maxWidth:"170px", maxHeight:"170px" }}
      
       />
      
      <img className="mx-5" src={Logo2} 
      style={{maxWidth:"170px", maxHeight:"170px" }}
      
       />
      
      <img className="mx-5" src={Logo3} 
      style={{maxWidth:"170px", maxHeight:"170px" }}
      
       />
      
      <img className="mx-5" src={Logo4} 
      style={{maxWidth:"170px", maxHeight:"170px" }}
      
       />
        <img className="mx-5" src={Logo1} 
      style={{maxWidth:"170px", maxHeight:"170px" }}
      
       />
      
        <img className="mx-5" src={Logo1} 
      style={{maxWidth:"170px", maxHeight:"170px" }}
      
       />
      
        <img className="mx-5" src={Logo1} 
      style={{maxWidth:"170px", maxHeight:"170px" }}
      
       />
        <img className="mx-5" src={Logo1} 
      style={{maxWidth:"170px", maxHeight:"170px" }}
      
       />
      
      <img className="mx-5" src={Logo2} 
      style={{maxWidth:"170px", maxHeight:"170px" }}
      
       />
      
      <img className="mx-5" src={Logo3} 
      style={{maxWidth:"170px", maxHeight:"170px" }}
      
       />
      
      <img className="mx-5" src={Logo4} 
      style={{maxWidth:"170px", maxHeight:"170px" }}
      
       />
        <img className="mx-5" src={Logo1} 
      style={{maxWidth:"170px", maxHeight:"170px" }}
      
       />
      
        <img className="mx-5" src={Logo1} 
      style={{maxWidth:"170px", maxHeight:"170px" }}
      
       />
      
        <img className="mx-5" src={Logo1} 
      style={{maxWidth:"170px", maxHeight:"170px" }}
      
       />
        <img className="mx-5" src={Logo1} 
      style={{maxWidth:"170px", maxHeight:"170px" }}
      
       />
      
      <img className="mx-5" src={Logo2} 
      style={{maxWidth:"170px", maxHeight:"170px" }}
      
       />
      
      <img className="mx-5" src={Logo3} 
      style={{maxWidth:"170px", maxHeight:"170px" }}
      
       />
      
      <img className="mx-5" src={Logo4} 
      style={{maxWidth:"170px", maxHeight:"170px" }}
      
       />
        <img className="mx-5" src={Logo1} 
      style={{maxWidth:"170px", maxHeight:"170px" }}
      
       />
      
        <img className="mx-5" src={Logo1} 
      style={{maxWidth:"170px", maxHeight:"170px" }}
      
       />
      
        <img className="mx-5" src={Logo1} 
      style={{maxWidth:"170px", maxHeight:"170px" }}
      
       />
            
    </div>
     */}