import slider from '../assets/PageMatterial/slider.jpg';
import Col from 'react-bootstrap/Col';

function Slider() {
  return (
    
    <div className="slider-container">   
    
       <div class="area" >         
      <img
        className="slider-image"
        src={slider}
        alt="Güvenilir lojistik"
      />     
      
      <ul class="circles">
                    <li></li>
                    <li></li>
                    <li></li>
                    <li></li>
                    <li></li>
                    <li></li>
                    <li></li>
                    <li></li>
                    <li></li>
                    <li></li>
            </ul>
      <Col sm={{ span: 1, offset: 1 }}>
        <div className="slider-text z-10">
          <h1 className='p-2' >
            Capacity, reliability, flexibility, and local expertise are key
          </h1>
        </div>
      </Col>
    </div>,
   
  
 
            
    </div>
  );
}

export default Slider;
