import React from 'react';
import airFreight from '../assets/AirFreight/Air_Freight.mp4'
import Ocean from '../assets/Ocean Freight/shipping-industry-carries-cargo-large-container-ships-generative-ai.jpg'
import Ground from '../assets/GroundFreight/trucks-highway-mountain-sunset.jpg'

function Services() {

  return(
    <div className='bg-main'>
    <div className='container col-10 col-md-8 mx-auto rounded p-2 pt-5 row card-hover  '>
    <div className='mx-auto col-12 text-center'>
    <h1 className='mx-auto text-center pb-1 highlighted-text fs-2 fw-medium' > Hizmetlerimiz </h1>
    <h2 className='mx-auto text-center fs-2' > Dünyaya Dokunun </h2>
    <p className='mx-auto text-center fw-medium mb-5 col-12 col-md-8' > Global çapta en güvenilir ve hızlı taşıma hizmetlerini gerçekleştirdiğimiz ve yüzlerce sektöre hizmet verdiğimiz yöntemlerimiz. </p>
    </div>
      <div className='col-12 col-md-4 mx-auto p-2 bg-main-ins shadow mt-4 row mb-md-5 ' style={{border:"#00000000 1px solid" , borderRadius:"10px"}}>
        <a href='/DenizYolu' className='m-0 p-0'>
        <img
        src={Ocean}   
        style={{
        borderRadius:"5px",
        width:"100%",
        height:"220px",
        objectFit:"cover"
        }}/> 
        </a>
        <h1 className='mx-auto text-center fs-4 pt-4'>Ocean Freight </h1>
        <p className='px-4 fw-medium'> Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s  </p>
        
        <a href="/DenizYolu" className='btn btn-outline-primary mx-auto text-center col-6 p-2 mt-4 mb-3'> Detaylar </a>

      </div>

      <div className='col-12 col-md-4 mx-auto p-2 bg-primary shadow mt-4 mt-md-2 row mb-md-5 ' style={{border:"#00000000 1px solid" , borderRadius:"10px"}}>
          <a href='/HavaYolu' className='mt-2 p-0'>
          <video
          src={airFreight}
          autoPlay
          loop="true"
          playsInline    
          style={{
          borderRadius:"5px",
          width:"100%",
          height:"220px",
          objectFit:"cover"
          }}/> 
          </a>
          <h1 className='mx-auto text-center text-light fs-4 pt-2'>Hava Taşımacılığı </h1>
          <p className='px-4 text-light fw-medium'> Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s  </p>
          <button className='btn btn-outline-light mx-auto text-center col-6 mt-4 mb-3 '> Detaylar </button>
      </div>

      <div className='col-12 col-md-4 mx-auto p-2 bg-main-ins shadow mt-4 row mb-md-5' style={{border:"#00000000 1px solid" , borderRadius:"10px"}}>
          <a href='airFreight' className='m-0 p-0'>
          <img 
          src={Ground}
          autoPlay
          loop="true"
          playsInline    
          style={{
          borderRadius:"5px",
          width:"100%",
          height:"220px",
          objectFit:"cover"
          }}/> 
          </a>
          <h1 className='mx-auto text-center fs-4 pt-4'>Kara Taşımacılığı </h1>
          <p className='px-4 fw-medium'> Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s  </p>
          <a href="/KaraYolu" className='btn btn-outline-primary mx-auto text-center col-6 p-2  mt-4 mb-3'> Detaylar </a>
          
      </div>

    </div>
    


    </div>

  )
}


export default Services;
