import React from "react";

function AboutText(){


    return(      
      <div className="about"> 
        <div className="bg-main mx-auto card-bg-image  h-100" >
          <div className="flow mb-2" style={{height:"120px"}}>
       
     </div>
            <div className="container-lg bg-transpanet text-center mx-auto" >
            <h1 className="mx-auto text-center highlighted-text fs-1 mt-5 " > Hakkımızda </h1>                  
            </div>
            <div className="container-lg row mx-auto col-12 col-lg-12 pb-3 pt-3" >
            
            <div className=" col-12 col-md-4">                   
              <div className="mx-1 mt-3 bg-main-ins p-md-3  p-3 cards rounded shadow">                   
                <p className=" fw-light text-dark  " style={{ lineHeight:"1.2rem"}} >
                Are you looking for logistics products? Challenge us with your industry requirements and your specific needs as a customer.
            </p>
              </div>    
            </div>    
            <div className=" col-12 col-md-4">                   
              <div className="mx-1 mt-3 bg-main-ins p-md-3 p-3 cards rounded shadow">                   
                <p className=" fw-light text-dark  " style={{ lineHeight:"1.2rem"}} >
             
                Our team is ready to determine fitting solutions. With our local and cross-border expertise, available at our locations, we will support you.
                Would you like to partner up with us to serve our customers? We are always looking for motivate subcontractors! So let’s join forces and become partners.</p>
              </div>    
            </div>    
            <div className=" col-12 col-md-4">                   
              <div className="mx-1 mt-3 bg-main-ins p-md-3 p-3 cards rounded shadow">                   
                <p className=" fw-light text-dark  " style={{ lineHeight:"1.2rem"}} >
                
                Would you like to partner up with us to serve our customers? We are always looking for motivate subcontractors! So let’s join forces and become partners.</p>
              </div>    
            </div>    
            <div className=" col-12 col-md-4">                   
              <div className="mx-1 mt-3 bg-main-ins p-md-3 p-3 cards rounded shadow ">                   
                <p className=" fw-light text-dark " style={{ lineHeight:"1.2rem"}} >Are you looking for logistics products? </p>
                
              </div>    
            </div>    
            <div className=" col-12 col-md-4">                   
              <div className="mx-1 mt-3 bg-main-ins p-md-3 p-3 cards rounded shadow">                   
                <p className=" fw-light text-dark  " style={{ lineHeight:"1.2rem"}} >
                Are you looking for logistics products? Challenge us with your industry requirements and your specific needs as a customer.
                Our team is ready to determine fitting solutions. With our local and cross-border expertise, </p>
              </div>    
            </div>    
            <div className=" col-12 col-md-4">                   
              <div className="mx-1 mt-3 bg-main-ins p-md-3 p-3 cards rounded shadow">                   
                <p className=" fw-light text-dark  " style={{ lineHeight:"1.2rem"}} >
               So let’s join forces and become partners.</p>
              </div>    
            </div>    
           
        
</div>
             </div>
        </div>
        
    )    
}

export default AboutText