import Headers from '../components/Header'
import Slider from '../components/Slider'
import AboutText from '../components/AboutText';
import Services from '../components/Services';
import Solutions from '../components/Solution';
import Brands from '../components/Brands';
import Footer from '../components/Footer';
import Timeline from '../components/Timeline';
import Action from '../components/Action';
import Counters from '../components/Counters';
import Markers from '../components/Markers';
import Video from '../components/Video';





function Home () {



    return (
 <div className=''>
<Headers/>
<Slider/>
<Brands/>
<Counters/>
<Markers/>
<Video/>
<Services/>

<Solutions/>

<Timeline/>
<Action/>
<Footer/>

</div>



)


}

export default Home ;