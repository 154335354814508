import React  from "react";



function Video (){




return(
<div className="bg-main  ">
<div className="container mx-auto p-md-5" >
<iframe className="px-md-5"
src="https://www.youtube.com/embed/4VpFUPvKYMM?si=yTKYk1cGbbTPe-4r"
title="Mers Video"
frameborder="0"
allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
referrerpolicy="strict-origin-when-cross-origin"
allowfullscreen 

style={{
    width : "100%",
    height : "42vh",
    objectFit:"contain"
   
   
}}



></iframe>
 
</div>

</div>

)

}

export default Video