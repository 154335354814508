import React from "react";

function Timeline (){

    return(
        <div className="bg-transparent" style={{ position:"relative", zIndex:"30", marginBottom:"-250px" }}  >
            <div className="shapedividers_com-4780" style={{position:"relative", zIndex:"30", height:"250px"}}>Timeline</div>
        </div>
    )
}

export default Timeline;