import React from 'react';
import 'animate.css';
import './App.css';
import './input.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min';
import './carousel.css'

import Home from '../src/pages/Home'

const App = () => (

  <div>
    <Home/>
    <div class="spinner-border text-primary text-center" role="status">
  <span class="visually-hidden">Loading...</span>
</div>
  </div>
);

export default App;
