import React from "react";
import $ from 'jquery';

function Counters() {
    React.useEffect(() => {
        $('.counter-value').each(function () {
            const targetValue = $(this).text() || "0"; 
            $(this).prop('Counter', 0).animate({
                Counter: parseInt(targetValue, 10) 
            }, {
                duration: 5500,
                easing: 'swing',
                step: function (now) {
                    $(this).text(Math.ceil(now));
                }
            });
        });
    }, []);

    return (
        <div className="bg-image-map">
            <div className="container col-md-8 mx-auto pt-2 pb-2">
                <div className="row mt-5 pt-2 mb-5 pb-5">
                    <div className="col-6 pt-5">
                        <div className="counter">
                            <div className="counter-content">
                                <div className="counter-icon">
                                    <i className="fa fa-globe"></i>
                                </div>
                                <h3>Ulaşılan Ülkeler</h3>
                            </div>
                            <span className="counter-value">20</span> {/* İlk counter için bir başlangıç değeri ekleniyor */}
                        </div>
                    </div>
                    <div className="col-md-6 col-6 pt-5">
                        <div className="counter green">
                            <div className="counter-content">
                                <div className="counter-icon">
                                    <i className="fa fa-rocket"></i>
                                </div>
                                <h3>Ulaşılan Şehirler</h3>
                            </div>
                            <span className="counter-value">171</span>
                        </div>
                    </div>
                    <div className="col-md-6 col-6 pt-5">
                        <div className="counter blue">
                            <div className="counter-content">
                                <div className="counter-icon">
                                    <i className="fa fa-user"></i>
                                </div>
                                <h3>Memnun Müşteriler</h3>
                            </div>
                            <span className="counter-value">6825</span>
                        </div>
                    </div>
                    <div className="col-md-6 col-6 pt-5">
                        <div className="counter gray">
                            <div className="counter-content">
                                <div className="counter-icon">
                                    <i className="fa fa-user"></i>
                                </div>
                                <h3>Ulaşıtırılan kargolar</h3>
                            </div>
                            <span className="counter-value">27334</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Counters;
