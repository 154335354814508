import React from "react";
import Actions from "../assets/logistic/callman.png"

function Action(){


    return(      
      <div className="about" style={{   overflow:"hidden !important"}}> 
        <div className="bg-main mx-auto card-bg-image  h-100" >
          <div className="flow mb-2" style={{height:"120px"}}>
       
     </div>
            <div className="container-lg bg-transpanet text-center mx-auto" >
            <h1 className="mx-auto text-center highlighted-text fs-1 mt-5 " > Güvenli ve Hızlı  </h1>                  
            </div>
   
   <div className="row">
   <div className="col-0 col-md-6 mx-auto text-center d-none d-md-block"> 
   <img
   src={Actions}
   style={{
    maxWidth:"850px",
    width:"100%",
    minHeight:"578px",    
    height:"570px",
    objectFit:"cover",
    backgroundPosition:"Top",
    objectPosition:"50% 1%",
     

   }}
    />
    </div>
    <div className="col-12 col-md-6 d-block">
    
   <p className="p-2 m-2 mx-auto text-light"> aasdsdasdSDASDASDASDASDASDASDs </p>
   <p className="p-2 m-2 mx-auto text-light"> aasdsdasdSDASDASDASDASDASDASDs </p>
   <p className="p-2 m-2 mx-auto text-light"> aasdsdasdSDASDASDASDASDASDASDs </p>
   <p className="p-2 mb-5 mx-auto text-light"> aasdsdasdSDASDASDASDASDASDASDs </p>
    
    
    </div>
    </div>
             </div>
        </div>
        
    )    
}

export default Action