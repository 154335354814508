import React from "react";
import SolutionsImg from "../assets/Ocean Freight/large-cargo-ship-with-containers-port-generative-ai.jpg"
import Logo1 from "../assets/Brands/logo.png"
import Logo2 from "../assets/Brands/logo2.png"
import Logo3 from "../assets/Brands/logo3.png"
import Logo4 from "../assets/Brands/logo4.png"
import Logo5 from "../assets/Brands/logo5.png"
import Logo6 from "../assets/Brands/logo6.png"
import Logo7 from "../assets/Brands/logo7.png"
import Logo8 from "../assets/Brands/logo8.png"
import Logo9 from "../assets/Brands/logo9.png"
import arrow from "../assets/PageMatterial/arrow.png"
import plane from "../assets/PageMatterial/plane.png"
import ship from "../assets/PageMatterial/ship.png"
import truck from "../assets/PageMatterial/truck.png"

function Brands (){

return(
  
<div className=" p-5 bg-main-ins  ">
<div className='shapedividers_com-9844'  style={{marginTop:"-90px", scale:"3", overflow:"hidden"}}> 
<div className="mx-auto text-center header-arrow">
<img  className=""
src={arrow}
style={{

  maxHeight:"17px",  
  rotate:"90deg",
  marginTop:"-17px", 
  filter:"sepia(100%)",
  filter: "contrast(0%) brightness(200%)",

  

}}

 />
  
</div>

</div>,
<div className="container row mx-auto pb-5" >
<div className=" col mx-auto text-center mt-2 a-img p-2" style={{marginTop:"-50px"}}>
<a className="a-img" href=""  >
<img src={plane}
style={{
  maxWidth:"170px",
  height:"100%",
  objectFit:"contain",
   

  }} />
  <p className="text-primary fw-bold fs-6 ">Hava Taşımacılığı ©Mers</p>
  </a>
</div>
<div className="col mx-auto text-center mt-2 p-2 a-img" style={{marginTop:"-50px"}}>
<a className="a-img" href=""  >
<img src={truck}
className=""
style={{
  maxWidth:"170px",
  height:"100%",
  objectFit:"contain",
  
  
  }} />
  <p className="text-primary fw-bold fs-6 ">Kara Taşımacılığı ©Mers</p>
</a>
</div>
<div className="col mx-auto text-center mt-2 a-img p-2" style={{marginTop:"-50px"}}>
<a className="a-img" href=""  >
<img src={ship}
style={{
  maxWidth:"170px",
  height:"100%",
  objectFit:"contain",
  
  }} />
  <p className="text-primary fw-bold fs-6 ">Deniz Taşımacılığı ©Mers</p>
</a>
</div>


</div>








</div>

)

}

export default Brands