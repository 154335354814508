import React from "react";
import SolutionsImg from "../assets/logistic/solution1.png"
import SolutionsImg2 from "../assets/logistic/solution2.png"

function Solutions (){


  return(
    <div className="bg-main p-2 p-md-5 pt-5 pt-md-0">
    <div className="container col-md-10 row mx-auto  rounded p-1 p-md-5 " >
    <div className="col-12 col-md-6 bg-main order-md-1 order-2 mb-5" > 
    <h1 className="fs-5 text-primary m-2 mt-5 ">Tedarik Zinciri Teknolojileri ve Özel Çözümler</h1>
    <h2 className="m-2">En İyi Performans Ve Verimlilik Avantajları!</h2>
    <p className="px-2 col-12  col-md-10 fw-medium">Dünyanın dört bir yanında güvenilir lojistik ve taşıma hizmeti veriyoruz. Ekibimiz, en yeni teknolojileri kullanarak tecrübelerini en iyi şekilde hayata geçiriyor. </p>
    <ul>
    <li className="text-primary fw-bold m-1">Şeffaf Fiyatlandırma, Çevresel Duyarlılık</li>
    <li className="text-primary fw-bold m-1" >7/24 Destek, Profesyonel ve Nitelikli Hizmet</li>
    <li className="text-primary fw-bold m-1">Gerçek Zamanlı Takip, Hızlı ve Verimli Teslimat</li>
    <li className="text-primary fw-bold m-1">Depo Stoklama, Kuruma Özel Çözümler</li>
    </ul>
    <div className="m-0 pt-2 ">
    <a href="" className=" btn btn-outline-primary col-12 col-md-5 mx-md-1" > Çözümler </a>
    <a href="" className=" btn btn-success col-12 col-md-5 fw-medium mx-md-2 mt-2 mt-md-0 "> Bizimle İletişime Geçin </a>
    

     </div>   

    </div>
    <div className="col-12 col-md-6   order-md-1 order-1 " > 
    <img 
    src={SolutionsImg}

    style={{
      objectFit:"contain",
      maxWidth:"100%",
      height:"100%",
      borderRadius:"10px",
      filter:"drop-shadow(60px 40px 50px #0d6efd)",
      transform: "scaleX(-1)"
    }}

    className=""


    />
    </div>

    <div className="col-12 col-md-6   order-md-2 order-2 " > 
    <img 
    src={SolutionsImg2}

    style={{
      objectFit:"contain",
      maxWidth:"100%",
      height:"100%",
      borderRadius:"10px",
      filter:"drop-shadow(60px -50px 50px #0d6efd)"
    }}

    className=""


    />
    </div>

    <div className="col-12 col-md-6 bg-main order-md-2 order-2 mb-5" > 
    <h1 className="fs-5 text-primary m-2 mt-5 ">Tedarik Zinciri Teknolojileri ve Özel Çözümler</h1>
    <h2 className="m-2">En İyi Performans Ve Verimlilik Avantajları!</h2>
    <p className="px-2 col-12  col-md-10 fw-medium">Dünyanın dört bir yanında güvenilir lojistik ve taşıma hizmeti veriyoruz. Ekibimiz, en yeni teknolojileri kullanarak tecrübelerini en iyi şekilde hayata geçiriyor. </p>
    <ul>
    <li className="text-primary fw-bold m-1">Şeffaf Fiyatlandırma, Çevresel Duyarlılık</li>
    <li className="text-primary fw-bold m-1" >7/24 Destek, Profesyonel ve Nitelikli Hizmet</li>
    <li className="text-primary fw-bold m-1">Gerçek Zamanlı Takip, Hızlı ve Verimli Teslimat</li>
    <li className="text-primary fw-bold m-1">Depo Stoklama, Kuruma Özel Çözümler</li>
    </ul>
    <div className="m-0 pt-2 ">
    <a href="" className=" btn btn-outline-primary col-12 col-md-5 mx-md-1" > Çözümler </a>
    <a href="" className=" btn btn-success col-12 col-md-5 fw-medium mx-md-2 mt-2 mt-md-0 "> Bizimle İletişime Geçin </a>
    

     </div>   

    </div>
    </div>


    </div>
    


  )
}

export default Solutions;